@import "_cstm-variable.scss";
@import "../_cstm-mixins.scss";

body {
  #fc_frame {
    z-index: 999 !important;

    @include mobile-view {
      right: 10px !important;
      bottom: 7px !important;
    }
  }
}

.otp_window {
  .modal-content {
    border-radius: 10px;
  }
}
.modal_otp_block {
  .modal-body {
    .form_row {
      .text_field {
        ngx-otp-input {
          .ng-star-inserted {
            width: 100%;
            min-height: auto !important;
            display: flex;
            justify-content: center;

            input {
              border-radius: 50%;
              font-size: 27px;
              color: $mid_gray_bg;

              @include mobile-view {
                width: 36px !important;
                height: 36px !important;
                margin: 0 4px;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.resetpass_modal_block {
  .modal-body {
    .form_row {
      .text_field {
        ngx-otp-input {
          .ng-star-inserted {
            width: 100%;
            min-height: auto !important;
            display: flex;
            justify-content: center;

            input {
              border-radius: 50%;
              font-size: 27px;
              color: $mid_gray_bg;

              @include mobile-view {
                width: 36px !important;
                height: 36px !important;
                margin: 0 4px;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.forgot_window {
  .modal-content {
    border-radius: 10px;
  }
}

.reset_pass_window {
  .modal-content {
    border-radius: 10px;
  }
}

.delete_account_window {
  .modal-content {
    border-radius: 10px;
  }
}
