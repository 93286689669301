@import "_cstm-variable.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-toastr/toastr.css";
@import "_cstm-mixins.scss";

.wrapper {
  position: relative;
  min-height: calc(100vh - 50px);
  width: 100%;
}

html {
  overflow-x: hidden;
}

body {
  font-family: $primary-font;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  // background: $bg-body;
  overflow-x: hidden;

  &.open {
    overflow: hidden;
  }

  &.bg_color {
    background: transparent;
  }

  &.cms-bgColor {
    background: $text-white;
  }

  &.remove_pad {
    padding-right: 0px !important;
  }
}

a {
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    transition: 0.3s;
  }
}

.pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.dobs {
  .p-calendar {
    @include tab-view {
      width: 100%;
    }
  }

  .form-control {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $box-border-color;
    font-size: 14px;
    padding: 8px 0;

    input {
      background-image: none !important;
      background-size: 20px;
      border: none;
      padding: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &.expiry_date {
    .p-calendar {
      @include tab-view {
        width: 100%;
      }
    }

    input {
      background: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/home-redesign/cal.svg")
        center right 15px no-repeat;
      background-size: 20px;
      border: none;
    }
  }
}

.dobs_account {
  .form-control {
    border-radius: 0;
    border: none;
    font-size: 14px;
    padding: 8px 0;

    input {
      background-image: none !important;
      border: none;
      padding: 0;
      color: $mid_gray_bg;

      @include tab-view {
        background: none;
        font-size: 14px;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &.expiry_date {
    .p-calendar {
      @include tab-view {
        width: 100%;
      }

      input {
        background: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/home-redesign/cal.svg")
          center right 0 no-repeat !important;
        background-size: 20px !important;
        border: none;
      }
    }
  }
}

.tripfluencer_modal {
  .modal-dialog {
    max-width: 745px;
  }
}

.pagovoy_down_modal {
  .modal-dialog {
    max-width: 600px;
  }
}

.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
}

.block_membership_warning_main {
  .modal-content {
    border-radius: 10px;
  }
}

.signin_modal {
  .modal-content {
    border-radius: 15px;
    border: 0;
  }
}

.signup_modal {
  .modal-content {
    border-radius: 15px;
    border: 0;
  }
}

.block_cookie_policy_main {
  .modal-content {
    border-radius: 10px;
  }
}

.block_session_expired_main {
  .modal-content {
    border-radius: 10px;
  }
}

.iframe_wrapper {
  &#spreedly-number {
    height: 42px;
    border-bottom: 2px solid $box-border-color;
    // @include tab-view {
    //   height: 45px;
    // }
  }

  &#spreedly-cvv {
    height: 42px;
    border-bottom: 2px solid $box-border-color;
    // @include tab-view {
    //   height: 45px;
    // }
  }
}

// discription popup
.section_description {
  border: 1px solid $primary;
  border-radius: 10px;
  padding: 10px 12px;
  font-size: 13px;
  font-weight: 400;
  color: $text-black;
  z-index: 999;
  background-color: $text-white;
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px #00000015;

  a {
    cursor: pointer;
    width: 30px !important;

    img {
      max-width: 9px;
    }
  }
}

p-calendar {
  input {
    &:read-only {
      pointer-events: auto;
    }
  }
}

input:focus {
  zoom: 100%;
}

input,
textarea {
  &:read-only {
    pointer-events: none;
  }
}

.form-control {
  &:focus {
    box-shadow: none;
  }

  &:read-only {
    background-color: $text-white;
  }
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */

input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */

input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */

textarea:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

.nation_wrps {
  .ng-select {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $box-border-color;
    font-size: 14px;
    padding: 0;
    bottom: 3px;

    .ng-select-container {
      border: none;
      // .ng-value-container {
      //     padding-left: 0;
      // }
    }

    .ng-arrow {
      content: "";
      padding: 5px 9px;
      background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_dropdown.svg);
      background-position: right;
      background-repeat: no-repeat;
      background-size: 12px;
      transition: 0.3s;
      right: 8px;
      border: none;
      top: 0px;

      @include tab-view {
        position: absolute;
        right: 0;
        height: 12px;
      }
    }

    &.ng-select-opened > .ng-select-container {
      .ng-arrow {
        padding: 5px 9px;
        background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_up_dropdown.svg);
        background-position: right;
        background-repeat: no-repeat;
        background-size: 12px;
        content: "";
        transition: 0.3s;
        right: 8px;
        border: none;
        top: 0px;

        @include tab-view {
          position: absolute;
          right: 0;
          height: 12px;
        }
      }
    }
  }
}

input,
button,
select {
  outline: none;

  &:focus {
    outline: none;
  }
}

.tab_search_area {
  input {
    &.p-inputtext {
      &:focus {
        box-shadow: none !important;
        font-family: "Poppins", sans-serif;
      }
    }
  }
}

.disabled {
  filter: grayscale(90%);
  pointer-events: none;
  opacity: 0.5;
}

.mandetory_wrps {
  .form-control {
    &.input-error {
      border-color: $error-color !important;
    }
  }

  &.drop_down_mandetory {
    .error_img {
      top: 40px;
      right: 25px;
    }
  }

  &.dobs_account {
    .error_img {
      top: 40px;
      right: 15px;
    }
  }
}

.error_field {
  color: $error-color;
  display: flex;
  margin-top: 5px;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;

  .error_img {
    display: flex;
    max-width: 10px;
    margin-right: 5px;
  }
}

//<!-- home slider css -->
//<!--  Start Banner Section css -->
.home_banner {
  .banner_inner {
    .banner_text {
      width: auto;

      h2 {
        font-size: 50px;
        color: $text-white;
        font-weight: 400;

        span {
          color: $primary;
          font-weight: 700;
        }

        @media (max-width: 767px) {
          font-size: 30px;
        }

        @media (max-width: 575px) {
          font-size: 27px;
        }
      }

      p {
        font-size: 29px;
        color: $text-white;

        @media (max-width: 767px) {
          font-size: 19px;
        }

        @media (max-width: 599px) {
          font-size: 16px;
        }
      }
    }

    .banner_tab {
      width: 100%;
      padding: 40px 0 0;

      .tab-content {
        .flight_tab {
          width: 100%;

          .nav-tabs {
            width: 100%;
            border: none;
            margin-bottom: 20px;

            li {
              display: inline-block;
              margin: 0 10px 0 0;

              a {
                display: flex;
                align-items: center;
                line-height: 32px;
                border-radius: 16px;
                padding: 0 8px;
                border: 1px solid $new_gray;
                background: $text-white;
                color: $new_gray;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.28px;

                img {
                  margin-right: 5px;
                  width: 18px;
                  height: 18px;
                }

                .active-icon {
                  display: none;
                }

                &.active {
                  color: $secondary;
                  background: $text-white;
                  border: 1px solid $secondary;

                  .active-icon {
                    display: block;
                  }

                  .default-icon {
                    display: none;
                  }
                }

                @media (max-width: 480px) {
                  font-size: 14px;

                  span {
                    display: inline-block;
                    font-size: 14px;
                  }
                }
              }

              &.upper_li {
                @include tab-view {
                  margin-right: 22px;

                  a {
                    width: 100px;

                    span {
                      width: 100%;
                      text-align: center;
                    }
                  }
                }
              }

              &.upper_li_2 {
                @include tab-view {
                  a {
                    width: 110px;

                    span {
                      width: 100%;
                      text-align: center;
                    }
                  }
                }
              }

              .class_section {
                // width: 110px;
                &.w_o_arrow {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  line-height: 32px;
                  border-radius: 16px;
                  padding: 0 8px;
                  border: 1px solid $text-grey;
                  background: $text-white;
                  color: $text-grey;
                  text-decoration: none;
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0.28px;

                  .search_area {
                    background-color: transparent;

                    .class_sec_info {
                      cursor: default;
                    }
                  }

                  .drop_arrow {
                    display: none;
                  }
                }

                @include mobile-view {
                  width: 80px;
                }

                label {
                  width: 100%;
                  margin-bottom: 0px;
                  font-size: 10px;
                  color: $text-grey;
                  font-weight: 400;
                  padding: 0 15px;
                  text-transform: uppercase;
                }

                .search_area {
                  background: $text-white;
                  position: relative;

                  .class_sec_info {
                    width: 100%;
                    height: 30px;
                    display: flex;
                    line-height: 30px;
                    font-size: 14px;
                    color: $text-grey;
                    position: relative;
                    padding: 0 !important;
                    //cursor: pointer;
                    justify-content: flex-end;

                    span {
                      font-size: 14px;
                      padding: 0 10px 0 0;
                    }

                    .drop_arrow {
                      width: 14px;
                      position: relative;
                      margin-left: 20px;
                      background-image: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_dropdown.svg");
                      background-position: right;
                      background-repeat: no-repeat;
                      background-size: 12px;
                    }
                  }

                  span {
                    font-size: 10px;
                    font-weight: 400;
                    color: $text-grey;
                    padding: 0 20px;
                  }
                }
              }

              .travellers {
                //width: 110px;
                margin-left: 0.5em;

                label {
                  width: 100%;
                  margin-bottom: 0px;
                  font-size: 10px;
                  color: $text-grey;
                  font-weight: 400;
                  padding: 0 15px;
                  text-transform: uppercase;
                }

                .search_area {
                  background: $text-white;
                  position: relative;

                  .travel_info {
                    width: 100%;
                    height: 30px;
                    display: flex;
                    line-height: 30px;
                    font-size: 14px;
                    color: $new_gray;
                    position: relative;
                    padding: 0;
                    cursor: pointer;
                    justify-content: flex-end;

                    span {
                      width: 15px;
                      font-size: 14px;
                      padding: 0 10px 0 0;
                    }

                    .drop_arrow {
                      width: 14px;
                      position: relative;
                      margin-left: 10px;
                      background-image: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_dropdown.svg");
                      background-position: right;
                      background-repeat: no-repeat;
                      background-size: 12px;
                    }
                  }

                  span {
                    font-size: 10px;
                    font-weight: 400;
                    color: $new_gray;
                    padding: 0 20px;
                  }

                  .traveller_add_block {
                    width: 260px;
                    position: absolute;
                    top: 118%;
                    background: $text-white;
                    //box-shadow: 0px 3px 15px $shadow-black;
                    padding: 15px;
                    border: 1px solid $grey_e_zero;
                    border-radius: 0 0 5px 5px;
                    z-index: 999;
                    right: 0;

                    @media (max-width: 425px) {
                      right: -170%;
                    }

                    ul {
                      margin: 0;
                      padding: 0;
                      list-style: none;

                      li {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 0 20px;

                        .left {
                          font-size: 10px;
                          font-weight: 400;
                          color: $text-grey;

                          span {
                            font-size: 14px;
                            color: $text-darkblue;
                            display: block;
                            padding: 0;
                          }
                        }

                        .right {
                          width: 100px;
                          display: flex;
                          align-items: center;

                          .qtyminus {
                            width: 30px;
                            height: 30px;
                            background: $text-white;
                            border: 2px solid $grey_border;
                            border-radius: 25px;
                            color: $grey_border;
                            padding: 0;
                            font-size: 18px;

                            &:hover {
                              border: 2px solid $grey_border;
                              color: $grey_border;
                            }

                            &.un_active {
                              border: 1px solid $feedback_text-clr !important;
                              color: $feedback_text-clr !important;
                              cursor: not-allowed;

                              &:hover {
                                border: 1px solid $feedback_text-clr !important;
                                color: $feedback_text-clr !important;
                                cursor: not-allowed;
                              }
                            }

                            &.disable_btn {
                              border: 1px solid $text-light-grey;
                              color: $text-light-grey;
                              cursor: not-allowed;
                              background: $f_one_darkGrey;
                            }
                          }

                          .qty {
                            width: 34px;
                            height: 30px;
                            font-size: 20px;
                            font-weight: 400;
                            color: $text-darkblue;
                            border: none;
                            text-align: center;
                          }

                          .qtyplus {
                            width: 30px;
                            height: 30px;
                            background: $text-white;
                            border: 2px solid $grey_border;
                            border-radius: 25px;
                            color: $grey_border;
                            padding: 0;
                            font-size: 18px;

                            &:hover {
                              border: 2px solid $grey_border;
                              color: $grey_border;
                            }
                          }
                        }

                        &:last-child {
                          padding: 0;
                        }
                      }
                    }
                  }
                }
              }
            }

            @include tab-view {
              flex-wrap: nowrap;
              margin-bottom: 0;

              li {
                margin: 0 5px 5px 0;

                &.class_resp {
                  width: 100%;
                  flex: 1;
                }

                &.travellers_resp {
                  width: 100%;
                }

                &:last-child {
                  margin: 0;

                  &.deal_switch_mobile {
                    display: flex !important;
                    justify-content: space-between;
                  }
                }
              }
            }
          }

          .tab-content {
            box-shadow: none;
            padding: 0;

            .tab-pane {
              .flight_search {
                position: relative;

                .flight-grid {
                  width: 100%;
                  list-style: none;
                  margin: 0;
                  padding: 0;

                  @media (max-width: 991px) {
                    flex-wrap: wrap;
                  }

                  .grid_box {
                    padding: 0 5px;

                    &.first {
                      width: 60%;
                      display: flex;
                      justify-content: space-between;
                      position: relative;

                      @media (max-width: 991px) {
                        width: 100%;
                        margin-bottom: 5px;
                      }

                      @include tab-view {
                        flex-flow: column;
                      }

                      @include tab-view {
                        padding: 0;
                      }

                      .flight_to {
                        .search_area {
                          .lab_wrapper {
                            label {
                              padding-right: 5px !important;
                              max-width: max-content !important;
                            }

                            .custom_inputs {
                              &.from_to_inputs {
                                width: 82%;
                              }
                            }
                          }
                        }
                      }

                      label {
                        width: 100%;
                        margin-bottom: 0px;
                        color: $new_gray;
                        font-size: 11px;
                        font-weight: 500;
                        padding: 0 15px;
                        //text-transform: uppercase;
                      }

                      .search_area {
                        padding: 8px 0;
                        border: 1px solid $box-border-color;
                        border-radius: 8px;
                        background: $text-white;

                        @include tab-view {
                          padding: 6px 0;
                        }

                        .comon_pcale {
                          .p-calendar {
                            width: 100%;
                          }
                        }

                        input {
                          width: 100%;
                          border: none;
                          height: 30px;
                          margin-bottom: 0px;
                          font-size: 16px;
                          color: $mine_black;
                          font-weight: 500;
                          padding: 0px;
                        }

                        span {
                          font-size: 10px;
                          font-weight: 400;
                          color: $new_gray;
                          padding: 0 15px;
                        }
                      }

                      .flight_form {
                        width: 49%;
                        position: relative;
                        z-index: 2;

                        @include tab-view {
                          width: 100%;
                          z-index: auto;
                          margin-bottom: 5px;
                        }
                      }

                      .flight_to {
                        width: 49%;
                        position: relative;
                        z-index: 10;

                        @include tab-view {
                          width: 100%;
                        }
                      }

                      .switch_btn {
                        width: 32px;
                        height: 32px;
                        background: $text-white;
                        border: 1px solid $box-border-color;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        z-index: 99;

                        img {
                          width: 14px;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                        }

                        @include tab-view {
                          left: 90%;
                        }
                      }
                    }

                    &.secound {
                      width: 30%;
                      display: flex;
                      justify-content: space-between;
                      position: relative;

                      @media (max-width: 991px) {
                        width: 100%;
                        margin-bottom: 20px;
                      }

                      @include tab-view {
                        margin-bottom: 5px;
                        padding: 0;
                      }

                      label {
                        width: 100%;
                        margin-bottom: 0px;
                        font-size: 10px;
                        color: $text-grey;
                        font-weight: 400;
                        padding: 0 15px;
                      }

                      .search_area {
                        padding: 8px 0;
                        border: 1px solid $box-border-color;
                        border-radius: 8px;
                        background: $text-white;
                        position: relative;

                        span {
                          &.p-calendar {
                            width: 100%;
                          }
                        }

                        input {
                          width: 100%;
                          border: none;
                          height: 48px;
                          margin-bottom: 1px;
                          font-size: 16px;
                          color: $text-black;
                          font-weight: 400;
                          padding: 0 15px 0 70px;
                          background: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/home-redesign/cal.svg")
                            center left 15px no-repeat !important;
                          cursor: pointer;

                          @media (max-width: 575px) {
                            .calender-responsive-view {
                              right: 0 !important;
                              left: auto !important;
                            }
                          }

                          @media (max-width: 1199px) {
                            font-size: 16px;
                          }

                          @include ipad-view {
                            font-size: 14px;
                            font-weight: 500;
                            padding: 0 0 0 67px;
                          }
                        }

                        .prev-next {
                          width: 60%;
                          display: flex;
                          padding: 0 0 0 15px;
                          justify-content: space-between;

                          a {
                            font-size: 10px;
                            color: $text-grey;
                          }
                        }

                        .date_icon {
                          position: absolute;
                          top: 50%;
                          right: 15px;
                          transform: translateY(-50%);
                        }
                      }

                      .date_form {
                        width: 100%;

                        p-calendar {
                          display: flex;
                          align-items: center;
                          height: 100%;
                        }

                        .search_area {
                          border-radius: 8px;
                          height: 100%;
                        }
                      }
                    }

                    &.third {
                      width: 10%;
                      padding-right: 0;

                      @media (max-width: 991px) {
                        width: 50%;
                        margin: 0 auto 20px;
                      }

                      @include tab-view {
                        width: 100%;
                        margin-bottom: 0;
                        padding: 0;
                      }

                      .serch_btn {
                        width: 100%;
                        height: 100%;

                        button {
                          width: 100%;
                          height: 100%;
                          padding: 10px 25px;
                          background-color: $primary;
                          background-size: 27px;
                          border-radius: 8px;
                          border: none;
                          font-size: 20px;
                          color: $text-white;
                          transition: 0.3s;
                          position: relative;
                          //z-index: 9;
                          overflow: hidden;
                          white-space: normal;
                          display: flex;
                          align-items: center;
                          justify-content: center;

                          img {
                            max-height: 29px;

                            &.hover_btn {
                              display: none;

                              &.show {
                                display: block;
                              }
                            }
                          }

                          &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 0%;
                            background-color: $text-white;
                            z-index: -1;
                            transition: 0.8s;
                            border-radius: 0 0 50% 50%;
                          }

                          // &:hover {
                          //   border: 1px solid $primary;
                          //   &:before {
                          //     height: 190%;
                          //   }
                          // }
                        }

                        @media (max-width: 480px) {
                          bottom: -60px;

                          button {
                            padding: 10px 20px;
                            font-size: 16px;
                            // img {
                            // 	margin-left: 30px;
                            // }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@-webkit-keyframes plane-animation {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

@keyframes plane-animation {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

//<!-- Close Our Mobile App Section css -->
//<!-- Start Footer Section css -->
.site_footer {
  width: 100%;

  .footer_top {
    width: 100%;
    padding: 50px 0 30px;
    background: $footer;

    @include tab-view {
      padding: 40px 0 25px;
    }

    .ftr_flex_row {
      display: flex;
      width: 100%;

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }

      .ftr_info {
        width: 100%;

        @media (max-width: 991px) {
          text-align: center;
          margin-bottom: 30px;
        }

        @include tab-view {
          margin-bottom: 20px;
        }

        a {
          width: auto;
          display: inline-block;
          margin-bottom: 15px;
          color: $box-border-color;

          @include tab-view {
            font-size: 12px;
          }

          img {
            display: inline-block;
          }
        }

        p {
          width: 100%;
          font-size: 14px;
          color: $box-border-color;
          margin: 0;
          font-weight: 600;
          // opacity: 0.7;
          text-align: left;

          @media (max-width: 768px) {
            text-align: center;
          }
        }
      }

      .ftr_menu {
        width: 100%;
        display: flex;

        @media (max-width: 991px) {
          flex-wrap: wrap;
        }

        .menu_grid {
          width: 25%;
          padding: 0 15px;

          &:last-child {
            width: 50%;
          }

          // @include ipad-view {
          //     width: 50%;
          //     margin-bottom: 30px;
          //     //&:last-child {
          //     //width: 50%;
          //     //}
          // }
          @include tab-view {
            width: 100%;
            text-align: center;
            margin-bottom: 0;

            &:last-child {
              width: 100%;

              .d-flex {
                justify-content: center;
                flex-flow: column;
              }
            }
          }

          h5 {
            width: auto;
            font-size: 14px;
            color: $box-border-color;
            font-weight: 600;
            margin: 0 10px 10px 0;
            white-space: nowrap;

            @include tab-view {
              margin: 30px 10px 15px 0;
              font-size: 12px;
            }
          }

          ul {
            width: 100%;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              width: 100%;
              padding: 0 0 10px;

              @include tab-view {
                padding: 0 0 20px;
              }

              a {
                font-size: 14px;
                color: $box-border-color;
                font-weight: 600;

                //opacity: 0.7;
                @include tab-view {
                  font-size: 12px;
                }

                &:hover {
                  color: $hotel;
                  opacity: 1;
                }
              }

              span {
                cursor: pointer;
                font-size: 14px;
                color: $box-border-color;
                font-weight: 600;

                //opacity: 0.7;
                @include tab-view {
                  font-size: 12px;
                }

                &:hover {
                  color: $hotel;
                  opacity: 1;
                }
              }

              &:last-child {
                padding: 0;
                margin: 0;

                @include tab-view {
                  padding: 0 0 20px;
                }
              }
            }
          }

          div:last-child {
            margin: 30px 0 0;
          }
        }
      }
    }
  }

  .footer_btm {
    width: 100%;
    padding: 15px 0;
    background: $footer_btm;
    text-align: center;

    p {
      width: 100%;
      font-size: 14px;
      color: $text-white;
      margin: 0;
      font-weight: 300;

      @include tab-view {
        font-size: 12px;
        padding: 0 20px;
      }
    }
  }
}

// <!-- Close Share section css -->
// <!-- Start Share Modal css -->
.share_modal {
  .modal-dialog {
    max-width: 514px;
    width: 96%;

    .modal-content {
      padding: 25px 40px;
      border-radius: 15px;

      @media (max-width: 767px) {
        padding: 20px 15px;
      }

      .block_modal_main {
        width: 100%;

        .modal_title {
          width: 100%;
          font-size: 40px;
          font-weight: 700;
          color: $text-black;
          margin: 0 0 30px;
          text-align: center;
          text-transform: uppercase;
          position: relative;

          .close {
            position: absolute;
            top: -5px;
            right: -5px;
            font-weight: normal;
            opacity: 1;
            z-index: 99;
          }

          @media (max-width: 575px) {
            font-size: 20px;
            padding: 0 20px;
          }
        }

        .modal_cnt {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

//<!-- Close Back To top Css -->
.block_contact_us_main {
  width: 100%;
  padding: 50px 0;

  .modal-content {
    border-radius: 10px;
  }
}

.block_cms_page_main {
  width: 100%;
  padding: 50px 0;

  .block_cmspage_comman {
    width: 100%;
    padding: 40px 30px;
    background: $text-white;
    border-radius: 5px;
    box-shadow: 3px 4px 10px $shadow-color;

    @media (max-width: 575px) {
      padding: 30px 20px;
    }

    .block_top {
      width: 100%;
      text-align: left;

      h6 {
        // text-transform: uppercase;
        font-size: 40px;
        font-weight: 700;
        margin: 0 0 30px;
        color: $text-black;

        @media (max-width: 767px) {
          font-size: 32px;
        }

        @media (max-width: 575px) {
          font-size: 24px;
        }
      }

      img {
        margin: 30px 0;
        max-width: 100%;
      }
    }

    .block_btm {
      width: 100%;

      .cms_im {
        float: right;
        margin: 0 100px 30px;
        max-width: 100%;

        @media (max-width: 991px) {
          margin: 0 auto 30px;
          float: none;
          display: inherit;
        }

        &.privacy_policy {
          margin: 0 0 30px 50px;
        }
      }

      .block_in_top {
        margin: 0 0 30px;
        display: flex;
        flex-flow: column;

        @media (max-width: 991px) {
          flex-wrap: wrap;
          margin: 0 0 20px 0;
          width: 100%;
        }

        .detail_left {
          width: auto;
          display: flex;
          align-items: center;

          //					@media(max-width:767px) {
          //						width: 100%;
          //						margin: 0 0 10px;
          //						text-align: center;
          //					}
          img {
            object-fit: cover;
            height: auto;
            width: auto;
            padding-bottom: 4px;
            position: inherit;
            max-width: 40px;
            max-height: 40px;
          }

          div {
            margin-left: 15px;

            p {
              font-size: 14px;
              font-weight: 400;
              color: $text-black;
              margin: 0 0 2px;
            }

            span {
              font-size: 12px;
              font-weight: 300;
              color: $text-grey;
            }
          }
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: $text-grey;
        text-align: justify;
      }
    }

    &.cms_laytrip {
      padding: 50px;
      word-break: break-word;

      .block_top {
        text-align: center;

        h6 {
          margin-bottom: 40px;
        }
      }

      .block_btm {
        width: 100%;

        .block_inn_top {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .block_inn_cnt {
            width: 66%;

            p {
              width: 100%;
              margin: 0 0 15px;
              font-size: 14px;
              color: $text-grey;
              font-weight: 500;
              line-height: 21px;
            }
          }
        }

        .block_inn_btm {
          width: 100%;

          h5 {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            color: $text-black;
            margin: 0 0 15px;
          }

          .block_cmn {
            width: 100%;
            margin: 0 0 30px;

            &:last-child {
              margin: 0;
            }

            span {
              width: 100%;
              display: block;
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              color: $background-blue;
              margin: 0 0 18px;
            }

            p {
              width: 100%;
              margin: 0 0 15px;
              font-size: 14px;
              color: $text-grey;
              font-weight: 500;
              line-height: 21px;
            }

            a {
              font-size: 14px;
              color: $text-lightblue;
              font-weight: 500;
              line-height: 21px;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &.cms_faq {
      max-width: 760px;
      margin: 0 auto;
      box-shadow: none;
      padding: 0;

      @include tab-view {
        padding: 0;
      }

      .block_top {
        text-align: center;

        h6 {
          margin-bottom: 30px;
          line-height: 60px;
        }
      }

      .block_faq_info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 0 50px;

        &:last-child {
          margin: 0;
        }

        .block_text {
          width: auto;

          .block_heading {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            color: $background-blue;
            margin-bottom: 25px;

            span {
              display: block;
              font-size: 14px;
              color: $text-grey;
              font-weight: 300;
              line-height: 21px;
            }
          }

          .accordion {
            width: 100%;

            .card {
              width: 100%;
              margin: 0 0 30px;
              border: none;

              @include tab-view {
                margin: 0 0 20px;
              }

              .card-header {
                padding: 0;
                background: transparent;
                border: none;

                .btn-link {
                  width: 100%;
                  padding: 0 25px 0 0;
                  font-size: 20px;
                  color: $text-black;
                  font-weight: 500;
                  line-height: 26px;
                  text-align: left;
                  position: relative;
                  display: flex;
                  align-items: center;

                  @include tab-view {
                    font-size: 16px;
                  }

                  &:hover {
                    text-decoration: none;
                  }

                  &:focus {
                    text-decoration: none;
                  }

                  &[aria-expanded="false"] {
                    &::after {
                      content: "";
                      display: none;
                      //position: relative;
                      position: absolute;
                      right: 0;
                      padding: 5px 9px;
                      margin-left: 10px;
                      background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_dropdown.svg);
                      background-position: right;
                      background-repeat: no-repeat;
                      background-size: 12px;

                      @include tab-view {
                        position: absolute;
                        right: 0;
                        height: 12px;
                      }
                    }
                  }

                  &[aria-expanded="true"] {
                    &::after {
                      content: "";
                      display: none;
                      //position: relative;
                      position: absolute;
                      right: 0;
                      padding: 5px 9px;
                      margin-left: 10px;
                      background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_up_dropdown.svg);
                      background-position: right;
                      background-repeat: no-repeat;
                      background-size: 12px;

                      @include tab-view {
                        position: absolute;
                        right: 0;
                        height: 12px;
                      }
                    }
                  }
                }
              }

              &.active {
                .card-header {
                  .btn-link {
                    color: $text-darkblue;

                    &:before {
                      background: $text-darkblue;
                    }
                  }
                }
              }

              .card-body {
                padding: 0;
                font-size: 18px;
                color: $text_grey_dark;

                p {
                  font-size: 18px;
                  color: $text_grey_dark;
                  font-weight: 400;
                  line-height: 26px;
                  margin: 0 0 15px;

                  &:last-child {
                    margin: 0;
                  }
                }

                @include tab-view {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .block_im {
          width: auto;
          padding: 0 0 0 50px;
        }
      }
    }
  }

  &.page_trems {
    padding: 0 0 70px;

    //font-family: "Poppins", sans-serif;
    @include tab-view {
      padding: 0 0 70px;
    }

    .page_topbanner {
      margin: 0 0 25px;
      width: 100%;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }

    .block_cms_cnt {
      width: 100%;

      .page_back_link {
        margin-bottom: 30px;

        @include tab-view {
          margin-left: 15px;
        }

        a {
          color: $secondary;
          font-size: 15px;
          font-weight: 500;
        }
      }

      .block_cmspage_comman {
        max-width: 760px;
        margin: 0 auto;
        box-shadow: none;
        padding: 0;

        h1 {
          color: $secondary;
        }

        h3 {
          color: $secondary;
          font-size: 34px;
          font-weight: 500;
          margin-bottom: 30px;
          font-family: "Poppins", sans-serif;

          @include tab-view {
            font-size: 24px;
            margin-bottom: 30px;
            line-height: 1;
          }
        }

        p {
          font-size: 18px;
          line-height: 26px;
          color: $mid_gray_bg;
          margin: 0;

          @include tab-view {
            font-size: 14px;
          }

          font {
            font-size: 18px;
            font-family: "Poppins", sans-serif;

            @include tab-view {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.subscribe {
  .block_cmn {
    p {
      color: $text_grey_dark !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }
}

body {
  padding-right: 0px !important;

  &.modal-open {
    padding: 0 !important;

    .site_header {
      &.fixed-top {
        z-index: 99;
      }
    }
  }
}

#challenge-modal {
  iframe {
    height: 318px;
    box-shadow: none;
    border-width: 0;
    width: 250px;

    .acs {
      &.one {
        width: 100%;
      }
    }
  }
}

.custom_toastr {
  border: 1px solid $primary;
  border-radius: 10px;
  padding: 10px 12px;
  font-size: 13px;
  font-weight: 400;
  color: $text-black;
  z-index: 999;
  background-color: $text-white;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px #00000015;
  width: 240px;
  transform: translate(-101%, 28%);
  max-width: 240px;

  a {
    cursor: pointer;
    width: 30px !important;

    img {
      max-width: 9px;
    }
  }
}

.custom_toastr_title {
  font-weight: 600;
}

.custom_toastr_message {
  margin-top: 5px;
}

ngx-otp-input {
  .inputStyles {
    width: 64px !important;
    height: 64px !important;
  }
}

.es_lang {
  .result_listing {
    &.hotel_map_listings {
      .search_block {
        .listing_block {
          .block_main {
            .block_detail_btm {
              .show_detail {
                .hotel_anchor {
                  width: auto !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .home_banner {
    .banner_inner {
      .banner_tab {
        .tab-content {
          .flight_tab {
            .nav-tabs {
              li {
                &.upper_li_2 {
                  a {
                    @include tab-view {
                      width: auto;
                      padding: 0 5px;
                    }
                  }
                }
              }

              @include tab-view {
                flex: auto;

                &.nav {
                  &:nth-child(2) {
                    @include tab-view {
                      margin-left: 0;
                    }
                  }
                }
              }
            }

            .tab-content {
              .tab-pane {
                .flight_search {
                  .lab_wrapper {
                    label {
                      max-width: max-content !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cstm_theme_btn_comman {
  font-size: 16px;
  color: $text-white !important;
  font-family: $primary-font;
  padding: 8px 34px;
  background: $sign_button !important;
  border-radius: 50px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: $text-white;
  }

  &.banner_new_btn {
    margin-top: 24px;

    &.sergio_banner_btn {
      background: $text-white !important;
      color: $primary !important;
    }
  }

  &.header_signin_btn {
    font-size: 14px;
    padding: 5px 24px !important;

    @include ipad-view {
      padding: 5px 20px !important;
    }
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.terms_block_margin {
  margin-bottom: 30px;
}

.terms_block_margin_last {
  margin-bottom: 0px;
}

input[type="checkbox"] {
  cursor: pointer;
}

input[type="checkbox"]:disabled {
  cursor: default;
}

.custom_checkbox {
  label {
    cursor: pointer;
  }
}

.attempt_warning {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  text-align: left !important;
  transform: translate(-50%, -50%) !important;
}

.travellers {
  //width: 110px;
  margin-left: 0.5em;

  label {
    width: 100%;
    margin-bottom: 0px;
    font-size: 10px;
    color: $text-grey;
    font-weight: 400;
    padding: 0 15px;
    text-transform: uppercase;
  }

  .search_area {
    background: $text-white;

    .travel_info {
      width: 100%;
      display: flex;
      font-size: 14px;
      padding: 0;
      cursor: pointer;
      justify-content: flex-end;

      span {
        font-size: 14px;
        padding: 0 10px 0 0;
        @include mobile-sm-view {
          padding: 0 10px 0 0;
          font-size: 14px;
        }
      }

      .drop_arrow {
        width: 14px;
        position: relative;
        margin-left: 10px;
        background-image: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_dropdown.svg");
        background-position: right;
        background-repeat: no-repeat;
        background-size: 12px;

        @include ipad-view {
          margin-left: 3px;
        }
      }
    }

    span {
      font-size: 10px;
      font-weight: 400;
      padding: 0 20px;
    }

    .traveller_add_block {
      width: 260px;
      position: absolute;
      top: 100%;
      background: $text-white;
      padding: 15px;
      border: 1px solid $grey_e_zero;
      border-radius: 0 0 5px 5px;
      z-index: 9999;
      cursor: default;

      &.tripBlock {
        padding: 0px !important;
        width: 140px !important;
        left: 0px;

        li {
          cursor: pointer;
        }

        .tripLi {
          padding: 15px;
          @include tab-view {
            padding: 10px;
          }
        }
      }

      &.travelerBlock {
        right: 0px;
      }

      @media (max-width: 425px) {
        right: -40%;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0 20px;
          cursor: default;
          .left {
            font-size: 10px;
            font-weight: 400;
            color: $text-grey;

            span {
              font-size: 14px;
              color: $text-darkblue;
              text-align: left;
              display: block;
              padding: 0;
            }
          }

          .right {
            width: 100px;
            display: flex;
            align-items: center;

            .qtyminus {
              width: 30px;
              height: 30px;
              background: $text-white;
              border: 2px solid $grey_border;
              border-radius: 25px;
              color: $grey_border;
              padding: 0;
              font-size: 18px;

              &:hover {
                border: 2px solid $grey_border;
                color: $grey_border;
              }

              &.un_active {
                border: 2px solid $feedback_text-clr !important;
                color: $feedback_text-clr !important;
                cursor: not-allowed;

                &:hover {
                  border: 2px solid $feedback_text-clr !important;
                  color: $feedback_text-clr !important;
                  cursor: not-allowed;
                }
              }

              &.disable_btn {
                border: 2px solid $text-light-grey;
                color: $text-light-grey;
                cursor: not-allowed;
                background: $f_one_darkGrey;
              }
            }

            .qty {
              width: 34px;
              height: 30px;
              font-size: 20px;
              font-weight: 400;
              color: $text-darkblue;
              border: none;
              text-align: center;
            }

            .qtyplus {
              width: 30px;
              height: 30px;
              background: $text-white;
              border: 2px solid $grey_border;
              border-radius: 25px;
              color: $grey_border;
              padding: 0;
              font-size: 18px;

              &:hover {
                border: 2px solid $grey_border;
                color: $grey_border;
              }
            }
          }

          &:last-child {
            padding: 0;
          }
        }
      }
    }
  }
}

.flight_search {
  position: relative;

  .flight-grid {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    .grid_box {
      padding: 0 5px;

      &.first {
        width: 60%;
        display: flex;
        justify-content: space-between;
        position: relative;

        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 5px;
        }

        @include tab-view {
          flex-flow: column;
        }

        @include tab-view {
          padding: 0;
        }

        .flight_to {
          .search_area {
            .lab_wrapper {
              label {
                padding-right: 5px !important;
                max-width: max-content !important;
              }

              .custom_inputs {
                &.from_to_inputs {
                  width: 82%;
                }
              }
            }
          }
        }

        label {
          width: 100%;
          margin-bottom: 0px;
          color: $new_gray;
          font-size: 11px;
          font-weight: 500;
          padding: 0 15px;
          //text-transform: uppercase;
        }

        .search_area {
          padding: 8px 0;
          border: 1px solid $box-border-color;
          border-radius: 8px;
          background: $text-white;

          @include tab-view {
            padding: 6px 0;
          }

          .comon_pcale {
            .p-calendar {
              width: 100%;
            }
          }

          input {
            width: 100%;
            border: none;
            height: 30px;
            margin-bottom: 0px;
            font-size: 16px;
            color: $text-black;
            font-weight: 400;
            padding: 0px;
          }

          span {
            font-size: 10px;
            font-weight: 400;
            color: $new_gray;
            padding: 0 15px;
          }
        }

        .flight_form {
          width: 49%;
          position: relative;
          z-index: 2;

          @include tab-view {
            width: 100%;
            z-index: auto;
            margin-bottom: 5px;
          }
        }

        .flight_to {
          width: 49%;
          position: relative;
          z-index: 10;

          @include tab-view {
            width: 100%;
          }
        }

        .switch_btn {
          width: 32px;
          height: 32px;
          background: $text-white;
          border: 1px solid $box-border-color;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          z-index: 99;

          img {
            width: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          @include tab-view {
            left: 90%;
          }
        }
      }

      &.secound {
        position: relative;
        padding: 0;

        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 20px;
        }

        @include tab-view {
          margin-bottom: 5px;
          padding: 0;
        }

        label {
          width: 100%;
          margin-bottom: 0px;
          font-size: 10px;
          color: $text-grey;
          font-weight: 400;
          padding: 0 15px;
        }

        .search_area {
          padding: 8px 0;
          border: 1px solid $box-border-color;
          border-radius: 8px;
          background: $text-white;
          position: relative;

          span {
            &.p-calendar {
              width: 100%;
            }
          }

          input {
            width: 100%;
            border: none;
            height: 48px;
            margin-bottom: 1px;
            font-size: 16px;
            color: $mine_black;
            font-weight: 500;
            padding: 0 15px 0 70px;
            background: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/home-redesign/cal.svg")
              center left 15px no-repeat !important;
            cursor: pointer;

            @media (max-width: 575px) {
              .calender-responsive-view {
                right: 0 !important;
                left: auto !important;
              }
            }

            @media (max-width: 1199px) {
              font-size: 16px;
            }

            @include ipad-view {
              font-size: 14px;
              font-weight: 500;
              padding: 0 0 0 67px;
            }
          }

          .prev-next {
            width: 60%;
            display: flex;
            padding: 0 0 0 15px;
            justify-content: space-between;

            a {
              font-size: 10px;
              color: $text-grey;
            }
          }

          .date_icon {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
          }
        }

        .date_form {
          width: 100%;

          p-calendar {
            display: flex;
            align-items: center;
            height: 100%;
          }

          .search_area {
            border-radius: 8px;
            height: 100%;

            @include tab-view {
              height: 58px;
            }
          }
        }
      }

      &.third {
        width: 10%;
        padding-right: 0;

        @media (max-width: 991px) {
          width: 50%;
          margin: 0 auto 20px;
        }

        @include tab-view {
          width: 100%;
          margin-bottom: 0;
          padding: 0;
        }

        .serch_btn {
          width: 100%;
          height: 100%;

          button {
            width: 100%;
            height: 100%;
            padding: 10px 25px;
            background-color: $primary;
            background-size: 27px;
            border-radius: 8px;
            border: none;
            font-size: 20px;
            color: $text-white;
            transition: 0.3s;
            position: relative;
            overflow: hidden;
            white-space: normal;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-height: 29px;

              &.hover_btn {
                display: none;

                &.show {
                  display: block;
                }
              }
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 0%;
              background-color: $text-white;
              z-index: -1;
              transition: 0.8s;
              border-radius: 0 0 50% 50%;
            }
          }

          @media (max-width: 480px) {
            bottom: -60px;

            button {
              padding: 10px 20px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.flight_search {
  position: relative;

  .flight-grid {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    .grid_box {
      padding: 0 5px;

      &.first {
        width: 60%;
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-left: 0;

        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 5px;
        }

        @include tab-view {
          flex-flow: column;
          margin-bottom: 5px;
          padding: 0;
        }

        @include tab-view {
          z-index: 999;
        }

        label {
          width: 100%;
          margin-bottom: 0px;
          font-size: 10px;
          color: $new_gray;
          font-weight: 400;
          padding: 0 15px;
        }

        .search_area {
          padding: 8px 0;
          border: 1px solid $box-border-color;
          border-radius: 8px;
          background: $text-white;

          @include tab-view {
            padding: 6px 0;
          }

          input {
            width: 100%;
            border: none;
            height: 30px;
            margin-bottom: 0px;
            font-size: 16px;
            color: $text-black;
            font-weight: 400;
            padding: 0 15px;
          }

          span {
            font-size: 10px;
            font-weight: 400;
            color: $new_gray;
            padding: 0 15px;
          }
        }

        .flight_form {
          width: 49%;
          position: relative;
          z-index: 11;

          @include tab-view {
            width: 100%;
            z-index: auto;
          }
        }

        .flight_to {
          width: 49%;
          position: relative;
          z-index: 11;

          @include tab-view {
            width: 100%;
            margin-top: 5px;
          }
        }

        .switch_btn {
          width: 32px;
          height: 32px;
          background: $text-white;
          border: 1px solid $box-border-color;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-55%, -50%);
          border-radius: 50%;
          z-index: 12;

          img {
            width: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          @include tab-view {
            left: 90%;
          }
        }
      }

      &.secound {
        position: relative;
        padding: 0;

        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 5px;
          padding: 0;
        }

        label {
          width: 100%;
          margin-bottom: 0px;
          font-size: 10px;
          color: $text-grey;
          font-weight: 400;
          padding: 0 15px;
        }

        .search_area {
          padding: 8px 0;
          border: 1px solid #a6a6a6;
          border-radius: 8px;
          background: $text-white;
          position: relative;

          span {
            &.p-calendar {
              width: 100%;
            }
          }

          input {
            width: 100%;
            border: none;
            height: 48px;
            margin-bottom: 1px;
            font-size: 16px;
            color: $mine_black;
            font-weight: 500;
            padding: 0 0 0 60px;
            background: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/home-redesign/cal.svg")
              center left 15px no-repeat !important;
            cursor: pointer;

            @media (max-width: 575px) {
              .calender-responsive-view {
                right: 0 !important;
                left: auto !important;
              }
            }

            @media (max-width: 1199px) {
              font-size: 16px;
              margin-left: 4px !important;
            }

            @include desktop {
              margin-left: 9px;
            }

            @include ipad-view {
              font-size: 14px;
              font-weight: 500;
              padding: 0 10px 0 67px;
            }
          }

          .prev-next {
            width: 60%;
            display: flex;
            padding: 0 0 0 15px;
            justify-content: space-between;

            a {
              font-size: 10px;
              color: $text-grey;
            }
          }

          .date_icon {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
          }
        }

        .date_form {
          width: 100%;

          p-calendar {
            display: flex;
            align-items: center;
            height: 100%;
          }

          .search_area {
            border-radius: 8px;
            height: 100%;
            height: 64px;
          }
        }
      }

      &.third {
        width: 10%;
        padding-right: 0;

        @media (max-width: 991px) {
          width: 50%;
          margin: 0 auto 20px;
        }

        @media (max-width: 575px) {
          width: 100%;
          padding: 0;
        }

        @include tab-view {
          margin-bottom: 0;
        }

        .serch_btn {
          width: 100%;
          height: 100%;

          button {
            width: 100%;
            height: 100%;
            padding: 10px 25px;
            background-color: $primary;
            background-size: 27px;
            border-radius: 8px;
            border: none;
            font-size: 20px;
            color: $text-white;
            transition: 0.3s;
            position: relative;
            overflow: hidden;
            white-space: normal;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-height: 29px;

              &.hover_btn {
                display: none;

                &.show {
                  display: block;
                }
              }
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 0%;
              background-color: $text-white;
              z-index: -1;
              transition: 0.8s;
              border-radius: 0 0 50% 50%;
            }
          }

          @media (max-width: 480px) {
            bottom: -60px;

            button {
              padding: 10px 20px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.flight_for_lt {
  position: relative;
  .flight-grid {
    width: 100%;
    display: flex;
    list-style: none;
    padding: 0;
    gap: 11px;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    .grid_box {
      padding: 0px;

      &.first {
        width: 60%;
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-left: 0;

        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 5px;
        }

        @include tab-view {
          flex-flow: column;
          margin-bottom: 0;
          padding: 0;
        }

        @include tab-view {
          z-index: 999;
        }

        label {
          width: 100%;
          margin-bottom: 0px;
          font-size: 10px;
          color: $new_gray;
          font-weight: 400;
          padding: 0 15px;
          //text-transform: uppercase;
        }

        .search_area {
          padding: 8px 0;
          border: 1px solid $grey_border;
          border-radius: 8px;
          background: $text-white;
          height: 100%;

          @include tab-view {
            padding: 6px 0;
            height: 55px;
          }

          input {
            width: 100%;
            border: none;
            height: 30px;
            margin-bottom: 0px;
            font-size: 16px;
            color: $mine_black;
            font-weight: 500;
            padding: 0px;
            @include ipad-view {
              font-size: 14px;
            }
          }

          span {
            font-size: 10px;
            font-weight: 400;
            color: $new_gray;
            padding: 0 15px;
          }
        }

        .flight_form {
          width: 49%;
          position: relative;
          z-index: 11;

          @include tab-view {
            width: 100%;
            z-index: auto;
          }
        }

        .flight_to {
          width: 49%;
          position: relative;
          z-index: 11;

          @include tab-view {
            width: 100%;
            margin-top: 5px;
          }
        }

        .switch_btn {
          width: 32px;
          height: 32px;
          background: $text-white;
          border: 1px solid $box-border-color;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-55%, -50%);
          border-radius: 50%;
          z-index: 12;

          img {
            width: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          @include tab-view {
            left: 90%;
          }
        }
      }

      &.secound {
        width: 30%;
        position: relative;
        padding: 0;

        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 0px;
          padding: 0;
        }

        label {
          width: 100%;
          margin-bottom: 0px;
          font-size: 10px;
          color: $text-grey;
          font-weight: 400;
          padding: 0 15px;
          //text-transform: uppercase;
        }

        .search_area {
          padding: 8px 0;
          // border: 1px solid $box-border-color;
          border: 1px solid #a6a6a6;
          border-radius: 8px;
          background: $text-white;
          position: relative;

          span {
            &.p-calendar {
              width: 100%;
            }
          }

          input {
            width: 100%;
            border: none;
            height: 48px;
            margin-bottom: 1px;
            font-size: 16px;
            color: $mine_black;
            font-weight: 500;
            padding: 0 0 0 60px;
            background: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/home-redesign/cal.svg")
              center left 15px no-repeat !important;
            cursor: pointer;

            @media (max-width: 575px) {
              .calender-responsive-view {
                right: 0 !important;
                left: auto !important;
              }
            }

            @media (max-width: 1199px) {
              font-size: 16px;
              margin-left: 4px !important;
            }

            @include desktop {
              margin-left: 9px;
            }

            @include ipad-view {
              font-size: 14px;
              font-weight: 500;
              padding: 0 10px 0 67px;
            }
          }

          .prev-next {
            width: 60%;
            display: flex;
            padding: 0 0 0 15px;
            justify-content: space-between;

            a {
              font-size: 10px;
              color: $text-grey;
            }
          }

          .date_icon {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
          }
        }

        .date_form {
          width: 100%;

          p-calendar {
            display: flex;
            align-items: center;
            height: 100%;
          }

          .search_area {
            border-radius: 8px;
            height: 100%;
            @media (max-width: 991px) {
              height: 55px;
            }
          }
        }
      }

      &.third {
        width: 10%;
        padding-right: 0;

        @media (max-width: 991px) {
          width: 50%;
          margin: 0 auto 20px;
        }

        @media (max-width: 575px) {
          width: 100%;
          padding: 0;
        }

        @include tab-view {
          margin-bottom: 0;
        }

        .serch_btn {
          width: 100%;
          height: 100%;

          button {
            width: 100%;
            height: 100%;
            padding: 10px 25px;
            background-color: $primary;
            background-size: 27px;
            border-radius: 8px;
            border: none;
            font-size: 20px;
            color: $text-white;
            transition: 0.3s;
            position: relative;
            overflow: hidden;
            white-space: normal;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-height: 29px;

              &.hover_btn {
                display: none;

                &.show {
                  display: block;
                }
              }
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 0%;
              background-color: $text-white;
              z-index: -1;
              transition: 0.8s;
              border-radius: 0 0 50% 50%;
            }
          }

          @media (max-width: 480px) {
            bottom: -60px;

            button {
              padding: 10px 20px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
p-calendar {
  input {
    &.p-inputtext {
      font-family: "Poppins", sans-serif;
    }
  }
}

.laytravelOverflow {
  overflow: unset;
}
